import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CommonImg',
    props: {
        size: {
            type:String,
            default:'',
        },
        width: {
            type:String,
            default:'',
        },
        height: {
            type:String,
            default:'',
        },
        imgUrl: {
            type:String,
            default:'#',
        },
    },
});




import { defineComponent, ref } from "vue";
import CommonImg from "@/common/common_img.vue";
import { useI18n } from "vue-i18n";
import { isAndroid, isIOS, isMobile } from "@/utils/is";
export default defineComponent({
  name: "Header",
  components: {
    CommonImg,
  },
  props: {
    msg: String,
  },
  setup() {
    const langName = ref("");
    const language = ref("");
    const isShowMenu = ref(false);
    const { locale } = useI18n();

    function showMenu(status: boolean) {
      isShowMenu.value = status;
    }

    function jump(location: number, isMobile: boolean) {
      if (isMobile) {
        isShowMenu.value = false;
      }
      console.log(isMobile, "isMobile");
      (window as any).scrollTo({
        top: location,
        behavior: "smooth",
      });
    }
    function handleSetLanguage(lang: string) {
      console.log("=====>");
      locale.value = lang;
      // if (lang === "zh") {
      //   langName.value = "中文";
      // } else {
      //   langName.value = "English";
      // }
      // language.value = lang;
      // localStorage.setItem("lang", lang);
      // 　　ElMessage.success('语言切换成功');
      // 　　location.reload();
    }
    function openGithub() {
      window.open("https://github.com/0xchat-app");
    }
    return {
      jump,
      showMenu,
      handleSetLanguage,
      openGithub,
      isMobile,
      isShowMenu,
    };
  },
});

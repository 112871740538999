import { $axios } from '@/utils/http.ts';
import config from '@/config';

interface IVideoGetArg {
  url:string
}

interface IVideoGetRe {
  data:any
}

interface IVideoInfoAPI {
  video: {
    get: (arg:IVideoGetArg) => IVideoGetRe
  }
}

export const getAudio = () => {
  return $axios({
    data: {
      'url':'https://vt.tiktok.com/ZSJvMGQPM/'
    },
    url: config.BASE_API
  });
};

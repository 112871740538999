const ENV = process.env.NODE_ENV;

const BASE_URL:any = {
  'production': '',
  'development': ''
};

const config = {
  BASE_API: BASE_URL[ENV],
  SCHEME: 'zbapp://', // zb官方app
  OTC_SCHEME: 'zbotcapp://', // otc项目  zbOtcApp
  ZB_DOWNLOAD: 'https://download.zbex.live/download/',
  WOW_MOBILE_IOS: 'https://testflight.apple.com/join/D4JHwCze',
  WOW_MOBILE_ANDROID: 'https://www.yowoworld.cc/download.html',
  WOW_WEB_URL: 'https://www.yowoworld.cc/h5',
  WOW_PC_MAC: 'https://imbn.oss-cn-shenzhen.aliyuncs.com/WowChat.dmg',
  WOW_PC_WINDOWS: 'https://imbn.oss-cn-shenzhen.aliyuncs.com/WowChat.zip'
};

export default config;

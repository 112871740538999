import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.imgUrl,
    style: _normalizeStyle({ height: _ctx.size ? _ctx.size : _ctx.height, width: _ctx.size ? _ctx.size : _ctx.width }),
    alt: ""
  }, null, 12, _hoisted_1))
}
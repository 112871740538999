import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "common_button",
    style: _normalizeStyle({
        width: _ctx.width + 'px',
        height:  _ctx.height + 'px',
        marginTop: _ctx.marginTop + 'px', 
        backgroundImage : _ctx.bgImg,
        backgroundColor: _ctx.bgColor,
      }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onTap()))
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
  ], 4))
}
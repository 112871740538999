
import { defineComponent } from "vue";
import CommonImg from "@/common/common_img.vue";
import { isAndroid, isIOS, isMobile } from "@/utils/is";

export default defineComponent({
  name: "Footer",
  components: {
    CommonImg,
  },
  props: {
    msg: String,
  },
});

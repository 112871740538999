
import { defineComponent } from "vue";
import commonButton from "@/common/common_button.vue";
import commonImg from "@/common/common_img.vue";
import { isAndroid, isIOS, isMobile } from "@/utils/is";
import { getAudio } from "../api/index.ts";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Home",
  components: {
    commonButton,
    commonImg,
    Header,
    Footer,
  },
  setup() {
    console.log(isMobile, "111");
    async function onTap(value: string) {
      getAudio();
      // const paramsObj: IObj<string> = {
      //   method: "openTiktokApp",
      // };

      // window.TCAPP.postMessage(JSON.stringify(paramsObj));
      // let urlScheme: string = "";
      // if (isAndroid) {
      //   console.log('======>isAndroid');
      //   urlScheme = "tiktok://";
      // } else if (isIOS) {
      //   urlScheme = "snssdk1233://";
      //   console.log('=====>isIOS');
      // }
      // setLocationUrl(urlScheme);
    }

    function setLocationUrl(url: string) {
      (document as any)
        .getElementById("common_button")
        .setAttribute("href", url);
      (document as any).getElementById("common_button").click();
    }

    return {
      onTap,
      isMobile,
    };
  },
});


import { defineComponent } from "vue";
import commonImg from "@/common/common_img.vue";
import { isAndroid, isIOS, isMobile } from "@/utils/is";
export default defineComponent({
  name: "App",
  components: {
    commonImg,
  },
  setup() {
    return {
      isMobile,
    };
  },
});

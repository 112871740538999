import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top_bg_wrap" }
const _hoisted_2 = { class: "middle_bg_wrap" }
const _hoisted_3 = { class: "bottom_bg_wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_commonImg = _resolveComponent("commonImg")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "bg_wrap",
      style: _normalizeStyle({ display: _ctx.isMobile ? 'none' : 'block' })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_commonImg, {
          width: "701",
          height: "801",
          imgUrl: require('@/assets/image/top_bg.png')
        }, null, 8, ["imgUrl"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_commonImg, {
          width: "701",
          height: "1686",
          imgUrl: require('@/assets/image/middle_bg.png')
        }, null, 8, ["imgUrl"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_commonImg, {
          size: "472",
          imgUrl: require('@/assets/image/bottom_bg.png')
        }, null, 8, ["imgUrl"])
      ])
    ], 4),
    _createVNode(_component_router_view)
  ], 64))
}
import axios from 'axios';

// 创建axios实例
const service = axios.create({
  baseURL: '', // api 的 base_url
  timeout: 90 * 1000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-type': 'application/json;charset=UTF-8'
    };
    console.log(config.url,'111');
    return config;
  },
  error => {
    // Do something with request error
    console.log(error,'====='); // for debug
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  response => {
    console.log(response,'res');
    const res = response.data
    if (res.code === '000000') {
      let result = JSON.parse(res.data);
      return Promise.resolve(result);
    }
    return Promise.reject(res);
  },
  error => {
    return Promise.reject(error);
  }
);

export const $axios = ({ data }:any) => {
  return service({
    params:data,
    url: '/shortlink/getInfo',
    method: 'get'
  });
};

import { defineComponent,ref } from 'vue';

export default defineComponent({
    name: 'CommonButton',
    emits: ['commonBtnClick'],
    props: {
        content: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '',
        },
        bgColor: {
            type: String,
            default: '',
        },
        bgImg: {
            type: String,
            default: '',
        },
        marginTop: String,
    },
    setup(props, context) {
        const tesxtss = ref('111');
        function onTap() {
            
            // window.navigator.clipboard.readText()
            //     .then(text => {
            //         tesxtss.value = text;
            //         console.log('Pasted content: ', text);
            //     })
            //     .catch(err => {
            //         tesxtss.value = 'Failed';
            //         console.error('Failed to read clipboard contents: ', err);
            //     });
            context.emit('commonBtnClick', 'aaaaaa')
        }
        return {
            onTap,
            tesxtss
        }
    }
});
